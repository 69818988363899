import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Mypage } from '@features/mypage';
import { History } from '@features/mypage/History';

const HistoryPage = () => (
  <Layout>
    <Helmet>
      <title>マイページ | 購入履歴一覧 | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <Suspense fallback={<p>Loading...</p>}>
      <Mypage>
        <History />
      </Mypage>
    </Suspense>
  </Layout>
);

export default HistoryPage;
